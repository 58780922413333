<template>
  <div class="record-page">
    <headerBar :title="i18n.t('record.title')"  :showArrow="true" @back="back"></headerBar>

    <div class="search-main">
      <div class="search-line flex-center-between">
        <div class="time-box" @click="showDate(1)">
          {{form.start || i18n.t('record.label1')}}
        </div>
        <div class="time-box" @click="showDate(2)">
          {{form.end || i18n.t('record.label2')}}
        </div>
        <div class="btn flex-center" @click="search">
          <Icon name="search" size="10"></Icon>
          {{i18n.t('record.label3')}}
        </div>
      </div>
      <div class="tabs">
        <Tabs type="card" color="#65a8f7" :animated="true" @click="changeTab">
          <Tab :title="item" :name="i" v-for="(item, i) in tabList" :key="i"></Tab>
        </Tabs>
      </div>
    </div>
    <div class="record-list">
       <List
        v-model="loading"
        :finished="finished"
        :finished-text="i18n.t('common.noMore')"
        @load="onLoad">
        <div class="list flex-center-between" :class="tabIndex === 0 ? 'red' : ''" v-for="(item, i) in orderList" :key="i">
          <div>
            <div>{{item.CreatedAt}}</div>
            <div class="mt-10">{{tabIndex == 0 ? i18n.t('record.label4') + item.Address : i18n.t('record.label5')}}</div>
          </div>
          <div v-if="tabIndex === 1">+{{item.Amount}}</div>
          <div v-if="tabIndex === 0">-{{item.Price}}</div>
        </div>
       </List>
    </div>

    <Popup v-model:show="showModal" position="bottom">
      <DatetimePicker 
        style="width: 100%"
        v-model="currentDate"
        type="date"
        @confirm="changeDate"
        @cancel="cancel"
        :title="title"></DatetimePicker>
    </Popup>
  </div>
</template>
<script>
import { Button, List, Popup, DatetimePicker, Icon, Tabs, Tab } from 'vant'
import headerBar from '@/components/header'
import { getRechargeLog, getWithdrawLog } from '@/api/user'
import moment from 'moment'
export default {
  components: {
    headerBar,
    Button,
    List,
    Popup,
    DatetimePicker,
    Icon,
    Tabs,
    Tab
  },
  data() {
    return {
      form: {
        start: '',
        end: ''
      },
      currentDate: [],
      page: {
        page: 1,
        limit: 10
      },
      isInit: false,
      loading: false,
      finished: false,
      totalPage: 1,
      orderList: [],
      tabList: [this.i18n.t('record.tab1'), this.i18n.t('record.tab2')],
      title: '',
      showModal: false,
      tabIndex: 0
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    async init(isAdd) {
      this.loading = true
      let form = new FormData()
      form.append('page', this.page.page)
      form.append('limit', this.page.limit)
      let res = {}
      if (this.tabIndex == 0) {
        res = await getWithdrawLog(form)
      } else {
        res = await getRechargeLog(form)
      }
      if (res.data.Items) {
        if (isAdd) {
          this.orderList = this.orderList.concat(res.data.Items)
        } else {
          this.orderList = res.data.Items
        }
        this.totalPage = res.data.Pagination.totalPage
        if (this.totalPage <= this.page.page) {
            this.finished = true;
        }
      }
      this.isInit = true
      this.loading = false
    },
    onLoad() {
      this.page.page += 1
      this.init(true)
    },
    search() {
      this.page.page = 1
      this.init()
    },
    changeDate(date) {
      if (this.dateType == 1) {
        this.form.start = moment(date).format('YYYY-MM-DD')
      } else {
        this.form.end = moment(date).format('YYYY-MM-DD')
      }
      this.showModal = false
    },
    showDate(type) {
      this.dateType = type
      this.showModal = true
      if (type == 1) {
        this.title = this.i18n.t('record.label1')
      } else {
        this.title = this.i18n.t('record.label2')
      }
    },
    cancel() {
      this.showModal = false
    },
    changeTab(i) {
      this.tabIndex = i ? i : 0
      this.page.page = 1
      this.init()
    }
  }
}
</script>